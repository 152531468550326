exports.shouldUpdateScroll = ({ pathname, prevRouterProps,  getSavedScrollPosition}) => {
  // we want to keep scroll postion when you open a work detail modal
  const isWorksDetail = pathname.match(/\/works\/.+/)
  const prevLocation = prevRouterProps?.location
  if( isWorksDetail && prevLocation) {
    // just passing false doesn't work.
    // I can't understand why.
    // so we get previous postionY, then set its value when you open a modal.
    const prevPosition = getSavedScrollPosition(prevLocation)
    window.scrollTo(...(prevPosition || [0,0]))
    return false
  }
  return true
}

